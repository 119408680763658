import {
  Grid,
  Paper,
  Text,
  Title,
  Group,
  Button,
  SimpleGrid,
  Select,
  Radio,
  Checkbox,
  Divider,
  Tooltip,
  Flex,
  Popover,
  Drawer,
  TextInput,
  Tabs,
  Loader,
} from "@mantine/core";
import {
  IconCircleCheckFilled,
  IconUpload,
  IconInfoCircle,
  IconBackground,
  IconContainer,
} from "@tabler/icons-react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Dropzone, MIME_TYPES, FileWithPath } from "@mantine/dropzone";
import { BannerTheme } from "./BannerTheme";
import { ButtonTheme } from "./ButtonTheme";
import { ConsentConfig } from "../../../Models/consentConfig";
import './consent.css';
import { ConsentService } from "../../../services/consentService";
import { ConfigContext } from "../../../Context/configContext";
import { regulations, layouts, finalConsentConfig, themesConfig, languageOptions, defaultBannerContent } from "../../../utils/utils";
import { useDisclosure } from "@mantine/hooks";
import ReactQuill from 'react-quill';
import { PricingService } from "@/services/pricingService";
import { IconSquare } from "@tabler/icons-react";
import colorgradient from '@images/colorgradient.png';

declare var Cookiex: {
  new (): {
      init(theme: { domainId: string; domainName: string }): void;
  };
};

function BannerConfiguration(props:any): JSX.Element {
  const { config } = useContext(ConfigContext);
  const [layoutType, setLayoutType] = useState(props.consentConfigData?.theme?.layout || "Box");
  const [themeType, setThemeType] = useState(props.consentConfigData?.theme?.type || "Light");
  const [buttonStyle, setButtonStyle] = useState(props.consentConfigData?.theme?.theme?.buttonStyle || "Mixed");
  const [bannerValue, setBannerValue] = useState(props.consentConfigData?.theme?.alignment || "leftBottomPopUp");
  const [bannerContent, setBannerContent] = useState(props.consentConfigData?.theme?.bannerContent || defaultBannerContent);
  const [files, setFiles] = useState<FileWithPath[]>([]);
  const [tempFile, setTempFile] = useState<FileWithPath[]>([]);
  const [isLogoShown, SetLogoShown] = useState(true);
  const [language, setLanguage] = useState(props.consentConfigData?.language || 'en');
  const [customThemeStyle, setCustomThemeStyle] = useState<any>(props.consentConfigData?.theme || finalConsentConfig);
  const [consentConfig, setConsentConfig] = useState<ConsentConfig>(props.consentConfigData?.theme || finalConsentConfig);
  const initialRef = useRef(false);
  const [regulation, setRegulation] = useState<any>(props.consentConfigData?.theme?.regulation || regulations[0]);
  const [openedContent, { open: openContent, close: closeContent }] = useDisclosure(false); // For content drawer
  const [openedLayout, { open: openLayout, close: closeLayout }] = useDisclosure(false); // For layout drawer
  const [openedDesign, { open: openDesign, close: closeDesign }] = useDisclosure(false); // For layout drawer
  const [openedBannerContent, { open: openBannerContent, close: closeBannerContent }] = useDisclosure(false); // For layout drawer
  const [activeSection, setActiveSection] = useState("");
  const [data, setData] = useState<any>(null);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [loading, setLoading] = useState(false);
  
  const consentService = new ConsentService(config!);
  const pricingService = new PricingService(config!);

  useEffect(() => {
       if(props.consentConfigData?.theme) {
           setCustomThemeStyle(props?.consentConfigData?.theme);
           setConsentConfig(props?.consentConfigData?.theme);
           setLayoutType(props?.consentConfigData?.theme?.layout || "Box");
           setBannerValue(props?.consentConfigData?.theme?.alignment || "leftBottomPopUp");
           setThemeType(props?.consentConfigData?.theme?.type || "Light");
           setButtonStyle(props?.consentConfigData?.theme?.theme?.buttonStyle || "Mixed");
           SetLogoShown(props?.consentConfigData?.isLogoDisplay);
           setLanguage(props?.consentConfigData?.theme.language || 'en');
           setBannerContent(props.consentConfigData?.theme?.bannerContent || defaultBannerContent);
           fetchSubscriptions();
        }
       setTempFile([]);
       console.log(tempFile);
       if(!initialRef.current) {
          initialRef.current = true;
          generatePreview(true, regulation, true, bannerContent);
       }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.consentConfigData]);

  useEffect(() => {
    if(activeSection === 'Initial') {
      closeDesign();
      closeBannerContent();
    } else if(activeSection === 'Preferences') {
      closeLayout();
      closeBannerContent();
    } else if(activeSection === 'ReConsent') {
      closeDesign();
      closeLayout();
    }
    generatePreview(true, regulation, false, bannerContent);
  }, [activeSection]);

  const fetchSubscriptions = async () => {
    try {
      const response = await pricingService.getSubscriptions(props.domainId);
  
      const activeSubscriptions = Array.isArray(response?.data)
        ? response.data.filter((sub: any) => sub.status === 'active')
        : [];
  
      const firstActive = activeSubscriptions.length > 0 ? activeSubscriptions[0] : null;
  
      console.log("Active Subscription:", firstActive);
      setData(firstActive); // ✅ store only the first active subscription object
    } catch (error) {
      console.error("Error fetching subscriptions:", error);
      setData(null); // or {} if you prefer
    }
  };
    
  const generatePreview = (isPreview:boolean, regulationType:any, initialPreview:boolean, updatedBannerContent:any) => {
      
    if(consentConfig.isLogoDisplay)
        consentConfig.isLogoDisplay = isLogoShown;
      
      if(consentConfig.regulation)
        consentConfig.regulation = regulationType;

      consentConfig.bannerContent = bannerContent;

      if(isPreview) {
        generateHTML(regulationType, initialPreview, updatedBannerContent);
      }
  };

  const handleLanguage = (languageObj:any) => {
    setLanguage(languageObj);
    setTempFile([]);
    consentConfig.language = languageObj;
    generatePreview(true, regulation, false, bannerContent);
  };

  const handleContentChange = (field: string, value: string, isNested?: boolean, nestedField?: string) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    
    timeoutRef.current = setTimeout(() => {
      if (isNested && nestedField) {
        bannerContent[nestedField][field] = value
      } else {
        bannerContent[field] = value;
      }
     
      generatePreview(true, regulation, false, bannerContent);
    }, 500);
  };
  
  const generateHTML = (regulationType:any, initialPreview:boolean, updatedBannerContent:any) => {
      const loadExternalScript = (src:any) => {
        return new Promise<void>((resolve, reject) => {
          const script = document.createElement('script');
          script.type = 'text/javascript';
          script.src = src;

          script.onload = () => {
              resolve();
          };

          script.onerror = () => {
              reject(new Error(`Failed to load script ${src}`));
          };

          document.head.appendChild(script);
        });
      }

      const scriptUrl = config?.bannerUrl + '/cookiex.min.js';

      setTimeout(function () {
        loadExternalScript(scriptUrl)
        .then(() => {
            const theme : any = {
                domainId: props.domainId,
                domainUrl: props.domainName,
                selectorId:'coookiex-comp-banner-preview',
                theme: {
                  layout : consentConfig.layout,
                  alignment : consentConfig.alignment || 'leftBottomPopUp',
                  bannerContent: updatedBannerContent,
                  theme : consentConfig?.theme,
                  type  : consentConfig.type,
                  regulation : regulationType,
                  isModalOpened: activeSection === 'Preferences',
                  isReConsentOpened: activeSection === 'ReConsent',
                  isInitalConsent: activeSection === 'Initial' || activeSection === ''
                },
                initialPreview: initialPreview
            };

            const cookiex = new Cookiex();
            cookiex.init(theme);
        })
        .catch((error) => {
            console.error('Error loading external script:', error);
        });
      }, 1000);
  };

  const handleCustomThemeStyles = (styleType:any) => {
    const filteredTheme = themesConfig.filter(theme => theme.type === styleType);
    if (consentConfig.theme) {
        consentConfig.theme = filteredTheme[0];
    }
    consentConfig.type = styleType;
    setCustomThemeStyle(consentConfig);
    generatePreview(true, regulation, false, bannerContent);
    setTempFile([]);
  };

  const handleButtonStyles = (styleType: any) => {
    setButtonStyle(styleType); // Update the button style state
  
    // Create a shallow copy of the custom theme style object
    const updatedThemeStyle = { ...customThemeStyle };
  
    // Set button variants based on styleType
    const variant = styleType === "Outline" ? "outline" : "filled";
    updatedThemeStyle.buttonCustomizeVariant = variant;
    updatedThemeStyle.buttonAcceptVariant = variant;
    updatedThemeStyle.buttonRejectVariant = variant;
  
    // Set text color based on the theme type and styleType
    let textColor = "#fff";
    if (customThemeStyle.type === "Light" && styleType === "Outline") {
      textColor = "#0078b4";
    } else if (customThemeStyle.type === "Light" && styleType !== "Outline") {
      textColor = "#fff";
    }
  
    updatedThemeStyle.buttonCustomizeTextColor = textColor;
    updatedThemeStyle.buttonAcceptTextColor = textColor;
    updatedThemeStyle.buttonRejectTextColor = textColor;
  
    // Update the customThemeStyle state
    setCustomThemeStyle(updatedThemeStyle);
  
    // Update the consentConfig object and generate the preview
    const updatedConsentConfig = { ...consentConfig, theme: updatedThemeStyle };
    setConsentConfig(updatedConsentConfig);
    generatePreview(true, regulation, false, bannerContent);
  };
  
  const handleCustomStyles = (colorValue:any, type:any) => {
    const style = `${type}`;
    customThemeStyle.theme[style] = colorValue;
    setCustomThemeStyle(customThemeStyle);
    generatePreview(true, regulation, false, bannerContent);
    setTempFile([]);
  };

  const handleCustomButtonStyles = (colorValue:any, buttonType:any, type:any) => {
        const style = `${buttonType}${type}`;
        customThemeStyle.theme[style] = colorValue;
        setCustomThemeStyle(customThemeStyle);
        generatePreview(true, regulation, false, bannerContent);
        setTempFile([]);
  };

  const handleUpload = (file:any) => {
     setFiles(file);
     setTempFile(file);
     consentService.uploadClientLogo(props.domainId, file);
  };

  const saveConsentBannerConfig = async () => {
    setLoading(true);
    try {
       const res = await props.consentData(consentConfig);
       console.log(res);
    } finally {
        setLoading(false);
    }
  };
  
  return (
    <React.Fragment>
      <>
        <Grid>
          <Grid.Col span={{ base: 12, md: 12, lg: 12 }}>
            <Grid>
              <Grid.Col span={{ base: 12, md: 9, lg: 10 }}>
              <Group mb={10} gap={5}>
                  {/* Cookie Consent Banner Button */}
                  <Button
                    leftSection={<IconBackground size={12} />}
                    variant={activeSection === 'Initial' ? 'filled' : 'default'}
                    onClick={() => {
                      openLayout();
                      setActiveSection('Initial');
                    }}
                  >
                    Cookie Consent Banner
                  </Button>

                  {/* GDPR Buttons */}
                  {data && regulation.value === 'gdpr' && (
                    <>
                      {data.category !== 'ULTIMATE' ? (
                        <Tooltip multiline w={220} position="bottom" label="This feature is available for Ultimate subscription plan. Please upgrade your plan to access this." withArrow>
                          <Button disabled leftSection={<IconContainer size={12} />} variant="default">
                            Consent Preference Modal
                          </Button>
                        </Tooltip>
                      ) : (
                        <Button
                          leftSection={<IconContainer size={12} />}
                          variant={activeSection === 'Preferences' ? 'filled' : 'default'}
                          onClick={() => {
                            openDesign();
                            setActiveSection('Preferences');
                          }}
                        >
                          Consent Preference Modal
                        </Button>
                      )}

                      {data.category !== 'ULTIMATE' ? (
                        <Tooltip multiline w={220} position="bottom" label="This feature is available for Ultimate subscription plan. Please upgrade your plan to access this." withArrow>
                          <Button disabled leftSection={<IconSquare size={12} />} variant="default">
                            Consent Management Modal
                          </Button>
                        </Tooltip>
                      ) : (
                        <Button
                          leftSection={<IconSquare size={12} />}
                          variant={activeSection === 'ReConsent' ? 'filled' : 'default'}
                          onClick={() => {
                            openBannerContent();
                            setActiveSection('ReConsent');
                          }}
                        >
                          Consent Management Modal
                        </Button>
                      )}
                    </>
                  )}

                  {/* US Regulation Button */}
                  {data && regulation.value === 'us' && (
                    <>
                      {data.category !== 'ULTIMATE' ? (
                        <Tooltip multiline w={220} position="bottom" label="This feature is available for Ultimate subscription plan. Please upgrade your plan to access this." withArrow>
                          <Button disabled leftSection={<IconContainer size={12} />} variant="default">
                            Consent Preference Modal
                          </Button>
                        </Tooltip>
                      ) : (
                        <Button
                          leftSection={<IconContainer size={12} />}
                          variant={activeSection === 'Preferences' ? 'filled' : 'default'}
                          onClick={() => {
                            openDesign();
                            setActiveSection('Preferences');
                          }}
                        >
                          Consent Preference Modal
                        </Button>
                      )}
                    </>
                  )}
                </Group>
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 9, lg: 2 }}>
                  <Button
                    leftSection={loading ? <Loader size="xs" /> : null}
                    variant="filled"
                    size="sm"
                    onClick={saveConsentBannerConfig}
                    disabled={loading} // Prevents multiple clicks
                  >
                    {loading ? 'Publishing...' : 'Publish Changes'}
                  </Button>
              </Grid.Col>
            </Grid>
            <div className="cookiex-preview-wrapper">
              <div className="cookiex-preview-box">
                {props?.domainName?.startsWith("http") ? (
                  <iframe
                    src={props.domainName + '?hideHeader=true'}
                    title="Website Preview"
                    scrolling="no"
                    style={{ width: "100%", height: "100%", border: "none", pointerEvents: "none" }}
                  />
                ) : (
                  <img
                    src="https://cdn.cookiex.io/brand/assets/bg-banner-preview-default.png"
                    alt="Preview Background"
                    style={{ width: "100%", height: "100%", objectFit: "cover" }}
                  />
                )}
              </div>

              <div id="coookiex-comp-banner-preview" />
            </div>
          </Grid.Col>
        </Grid>
        <Drawer
          className="intial-banner-drawer"
          opened={openedLayout}
          onClose={closeLayout}
          title="Intial Banner"
          overlayProps={{ backgroundOpacity: 0, blur: 0 }}
          size="xs"
          closeOnClickOutside={false}
        >
          <Tabs variant="pills" defaultValue="layout">
            <Tabs.List>
              <Tabs.Tab value="layout">
                Layout
              </Tabs.Tab>
              <Tabs.Tab value="design">
                Design
              </Tabs.Tab>
              {data && data.category == 'ULTIMATE' && (
                <Tabs.Tab value="content">
                  Content
                </Tabs.Tab>
              )}
            </Tabs.List>

            <Divider mt={20} />      

            <Tabs.Panel value="layout">
                <Text size="xs" mb="xs" mt="lg" fw={500}>Consent Template</Text>
                <Flex>                      
                  <Select mr={10}
                    placeholder="Choose a regulation"
                    allowDeselect={false}
                    searchable
                    data={regulations.map((reg) => (
                        { value: reg.value, label: reg.label }
                    ))}
                    defaultValue={regulation.value}
                    onChange={(value) => {
                      const selectedRegulation = regulations.find((reg) => reg.value === value);
                      setRegulation(selectedRegulation);
                      if (consentConfig) {
                        consentConfig.regulation = selectedRegulation;
                      }
                      console.log('Selected Regulation:', selectedRegulation);
                      generatePreview(true, selectedRegulation, false, bannerContent);
                    }}
                  />
                  <Popover width={300} position="right" withArrow shadow="md" opened={openedContent}>
                    <Popover.Target>
                      <IconInfoCircle onMouseEnter={openContent} onMouseLeave={closeContent} />
                    </Popover.Target>
                    <Popover.Dropdown style={{ pointerEvents: 'none' }}>
                      <Text size="sm">{regulation?.description}</Text>
                    </Popover.Dropdown>
                  </Popover>
                </Flex>
                <Text size="xs" mt={30} mb="xs" fw={500}>Banner Layout{" "}</Text>

                <Grid>
                  {layouts.map((layout:any, index) => (
                    <Grid.Col key={index} span={{ base: 12, md: 4, lg: 4 }}>
                      <div>
                        <Paper
                          shadow="0px"
                          withBorder
                          p="lg"
                          style={{
                            position: 'relative',
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            minHeight: '80px',
                            height: '100%',
                          }}
                          onClick={() => {
                            setLayoutType(layout.layoutType);
                            setBannerValue(layout.bannerValue);
                            consentConfig.layout = layout.layoutType;
                            consentConfig.alignment = layout.bannerValue;
                            generatePreview(true, regulation, false, bannerContent);
                          }}
                        >
                          <div
                            className="fill-primary"
                            style={layout.styles}
                          ></div>
                          {layoutType === layout.layoutType && (
                            <IconCircleCheckFilled
                              className="primary"
                              size="1rem"
                              stroke={3}
                              style={{ left: "-8px", top: "-7px", position: "absolute" }}
                            />
                          )}
                        </Paper>
                        <Text size="xs" mt={5} ta="center">
                          {layout.text}
                        </Text>
                      </div>
                    </Grid.Col>
                  ))}
                </Grid>
                {layoutType == "Box" && (
                  <>
                    <Text size="xs" mt={30} mb="xs" fw={500}>Alignment{" "}</Text>
                    <Radio.Group mt={15} mb={15} value={bannerValue}
                      onChange={(e : any) => {
                        setBannerValue(e);
                        consentConfig.alignment = e;
                        generatePreview(true, regulation, false, bannerContent);
                      }}>
                      <SimpleGrid cols={2}>
                        <Radio size="xs" value="leftBottomPopUp" label="Bottom Left" />
                        <Radio size="xs" value="rightBottomPopUp" label="Bottom Right" />
                        <Radio size="xs" value="leftTopPopUp" label="Top Left" />
                        <Radio size="xs" value="rightTopPopUp" label="Top Right" />
                      </SimpleGrid>
                    </Radio.Group>
                  </>
                )}
                {languageOptions.length > 0 && (
                    <>
                      <Text size="xs" mt={30} mb="xs" fw={500}>Language{" "}</Text>
                      <Select allowDeselect={false} defaultValue={language} data={languageOptions} onChange={(event) => handleLanguage(event)} />
                    </>
                )}
            </Tabs.Panel>

            <Tabs.Panel value="design">
                <Select label="Theme" mt="lg" allowDeselect={false} defaultValue={props.consentConfigData?.theme?.type} onChange={(event) => handleCustomThemeStyles(event)} data={["Light", "Dark", "Custom"]} />
                <div>
                    {customThemeStyle?.type == 'Custom' && (
                      <div>
                          <BannerTheme customStyles={handleCustomStyles} background={customThemeStyle?.theme?.background || "#fff"} name="background" label="Background" description="" />
                          <BannerTheme customStyles={handleCustomStyles} background={customThemeStyle?.theme?.textColor || "#000"} name="textColor" label="Text Color" description="Choose the color of all texts within the banner" />
                          <BannerTheme customStyles={handleCustomStyles} background={customThemeStyle?.theme?.highlight || "#fff"} name="highlight" label="Highlight" description="Choose your highlight color that will impact all links and active toggles in your banner" />
                          <Divider my="sm" />
                          {regulation.value === 'gdpr' && (
                            <>
                            <Group justify="left"><Text size="xs"> Button 1 (Reject All) </Text></Group>
                            <ButtonTheme customStyles={handleCustomButtonStyles} background={customThemeStyle?.theme?.buttonRejectBackGround || "#fff"} label="BackGround" type="buttonReject" description="" />
                            <ButtonTheme customStyles={handleCustomButtonStyles} background={customThemeStyle?.theme?.buttonRejectTextColor || "#000"} label="TextColor" type="buttonReject" description="" />
                            <ButtonTheme customStyles={handleCustomButtonStyles} background={customThemeStyle?.theme?.buttonRejectBorder || "#fff"} label="Border" type="buttonReject" description="" />
                            <Divider my="sm" />
                            <Group justify="left"><Text size="xs">Button 2 (Accept) </Text></Group>
                            <ButtonTheme customStyles={handleCustomButtonStyles} background={customThemeStyle?.theme?.buttonAcceptBackGround || "#fff"} label="BackGround" type="buttonAccept" description="" />
                            <ButtonTheme customStyles={handleCustomButtonStyles} background={customThemeStyle?.theme?.buttonAcceptTextColor || "#000"} label="TextColor" type="buttonAccept" description="" />
                            <ButtonTheme customStyles={handleCustomButtonStyles} background={customThemeStyle?.theme?.buttonAcceptBorder || "#fff"} label="Border" type="buttonAccept" description="" />
                            <Divider my="sm" />
                            <Group justify="left"><Text size="xs">Button 3 (Customize)</Text></Group>
                            <ButtonTheme customStyles={handleCustomButtonStyles} background={customThemeStyle?.theme?.buttonCustomizeBackGround || "#fff"} label="BackGround" type="buttonCustomize" description="" />
                            <ButtonTheme customStyles={handleCustomButtonStyles} background={customThemeStyle?.theme?.buttonCustomizeTextColor || "#000"} label="TextColor" type="buttonCustomize" description="" />
                            <ButtonTheme customStyles={handleCustomButtonStyles} background={customThemeStyle?.theme?.buttonCustomizeBorder || "#fff"} label="Border" type="buttonCustomize" description="" />
                          </>
                        )}
                        {regulation.value === 'us' && (
                          <>
                            <Group justify="left" mt={20}><Text size="xs"> Button 1 (Do Not Sell My Info) </Text></Group>
                            <ButtonTheme customStyles={handleCustomButtonStyles} background={customThemeStyle?.theme?.buttonRejectBackGround || "#fff"} label="BackGround" type="buttonReject" description="" />
                            <ButtonTheme customStyles={handleCustomButtonStyles} background={customThemeStyle?.theme?.buttonRejectTextColor || "#000"} label="TextColor" type="buttonReject" description="" />
                            <ButtonTheme customStyles={handleCustomButtonStyles} background={customThemeStyle?.theme?.buttonRejectBorder || "#fff"} label="Border" type="buttonReject" description="" />
                          </>
                        )}
                      </div>
                    )}
                    {customThemeStyle?.type != 'Custom' && (
                      <Select mt={10} allowDeselect={false} label="Button Style" defaultValue={buttonStyle} onChange={(event) => handleButtonStyles(event)}  data={["Outline", "Mixed", "Solid"]} />
                    )}
                </div>
                <Divider my="sm" />
                {data && data.category == 'ULTIMATE' && (
                  <>
                    <Group justify="left" mt={10}>
                        <Title order={6} fw={500}>Logo</Title>
                        <Tooltip label="Add a logo to display on your banner">
                          <IconInfoCircle size="14"/>
                        </Tooltip>
                    </Group>
                    <Checkbox mt={4}
                      mb={10}
                      checked={isLogoShown}
                      onChange={(event) => SetLogoShown(event.currentTarget.checked)}
                      label="Display logo on banner"
                    />
                    <Dropzone
                      maxFiles={1}
                      accept={[MIME_TYPES.png, MIME_TYPES.jpeg, MIME_TYPES.svg, MIME_TYPES.gif]}
                      onDrop={handleUpload}
                    >
                      <Group justify="center">
                        <IconUpload size={15} color="blue" />
                        <Text size="xs" ta="center" c="blue">
                          Upload File
                        </Text>
                      </Group>
                    </Dropzone>
                    <Text size="xs" mt={10}>
                      Filetype jpg, jpeg, gif, svg
                    </Text>
                  </>
                )}
            </Tabs.Panel>

            <Tabs.Panel value="content">
              <>
                  <TextInput mt="lg"
                    label="Banner Title"
                    placeholder="Banner Title"
                    defaultValue={bannerContent.title}
                    onChange={(e) => handleContentChange("title", e.target.value, false, "")}
                  />
                  <Text size="sm" mt={10} mb="xs" fw={600}>Banner Description{" "}</Text>
                  <ReactQuill theme="snow" value={bannerContent.description} onChange={(value) => handleContentChange("description", value, false, "")} />
                  {data && data.category == 'ULTIMATE' && regulation.value === 'gdpr' && (
                    <>
                      <TextInput mt={10}
                        label={`"Accept All" Button`}
                        placeholder={`"Accept All" Button`}
                        defaultValue={bannerContent.Accept}
                        onChange={(e) => handleContentChange("Accept", e.target.value, false, "")}
                      />
                      <TextInput mt={10}
                        label={`"Reject All" Button`}
                        placeholder={`"Reject All" Button`}
                        defaultValue={bannerContent.Reject}
                        onChange={(e) => handleContentChange("Reject", e.target.value, false, "")}
                      />
                      <TextInput mt={10}
                        label={`"Customize" Button`}
                        placeholder={`"Customize" Button`}
                        defaultValue={bannerContent.Customize}
                        onChange={(e) => handleContentChange("Customize", e.target.value, false, "")}
                      />
                      <TextInput mt={10}
                        label={`"Cookie Policy URL"`}
                        placeholder={`"Cookie Policy URL"`}
                        defaultValue={bannerContent.policyUrl}
                        onChange={(e) => handleContentChange("policyUrl", e.target.value, false, "")}
                      />
                    </>
                  )}
                  {data && data.category == 'ULTIMATE' && regulation.value === 'us' && (
                      <TextInput mt={10}
                        label={`"Do Not Sell My Personal Info" Button`}
                        placeholder={`"Do Not Sell My Personal Information" Button`}
                        defaultValue={bannerContent.NotSellData}
                        onChange={(e) => handleContentChange("NotSellData", e.target.value, false, "")}
                    />
                  )}
              </>
            </Tabs.Panel>
          </Tabs>
        
        </Drawer>

        <Drawer
          className="banner-design-drawer"
          opened={openedDesign}
          onClose={closeDesign}
          title="Preferences Modal"
          overlayProps={{ backgroundOpacity: 0, blur: 0 }}
          size="xs"
          closeOnClickOutside={false}
        >
           <Tabs variant="pills" defaultValue="design">
            <Tabs.List>
              <Tabs.Tab value="design">
                Design
              </Tabs.Tab>
              {data && data.category == 'ULTIMATE' && (
                <Tabs.Tab value="content">
                  Content
                </Tabs.Tab>
              )}
            </Tabs.List>

            <Divider mt={20} />      

            <Tabs.Panel value="design">
                  <div>
                    <div>
                        <BannerTheme customStyles={handleCustomStyles} background={customThemeStyle?.theme?.bannerPrefBg || "#fff"} name="bannerPrefBg" label="Background" description="" />
                        <BannerTheme customStyles={handleCustomStyles} background={customThemeStyle?.theme?.bannerPrefTxtClr || "#000"} name="bannerPrefTxtClr" label="Text Color" description="Choose the color of all texts within the banner" />
                        <Divider my="sm" />
                        <Group justify="left"><Text size="xs"> Button 1 ({regulation.value === 'gdpr' ? 'Reject All':'Save Preferences'}) </Text></Group>
                        <ButtonTheme customStyles={handleCustomButtonStyles} background={customThemeStyle?.theme?.bannerPrefBtnRejBackGround || "#fff"} label="BackGround" type="bannerPrefBtnRej" description="" />
                        <ButtonTheme customStyles={handleCustomButtonStyles} background={customThemeStyle?.theme?.bannerPrefBtnRejTextColor || "#000"} label="TextColor" type="bannerPrefBtnRej" description="" />
                        <ButtonTheme customStyles={handleCustomButtonStyles} background={customThemeStyle?.theme?.bannerPrefBtnRejBorder || "#fff"} label="Border" type="bannerPrefBtnRej" description="" />
                        <Divider my="sm" />
                        <Group justify="left"><Text size="xs">Button 2 ({regulation.value === 'gdpr' ? 'Allow Selection':'Cancel'}) </Text></Group>
                        <ButtonTheme customStyles={handleCustomButtonStyles} background={customThemeStyle?.theme?.bannerPrefBtnAllowSelBackGround || "#fff"} label="BackGround" type="bannerPrefBtnAllowSel" description="" />
                        <ButtonTheme customStyles={handleCustomButtonStyles} background={customThemeStyle?.theme?.bannerPrefBtnAllowSelTextColor || "#000"} label="TextColor" type="bannerPrefBtnAllowSel" description="" />
                        <ButtonTheme customStyles={handleCustomButtonStyles} background={customThemeStyle?.theme?.bannerPrefBtnAllowSelBorder || "#fff"} label="Border" type="bannerPrefBtnAllowSel" description="" />
                        {regulation.value === 'gdpr' && (
                          <>
                            <Divider my="sm" />
                            <Group justify="left"><Text size="xs">Button 3 (Accept All)</Text></Group>
                            <ButtonTheme customStyles={handleCustomButtonStyles} background={customThemeStyle?.theme?.bannerPrefBtnAcptAllBackGround || "#fff"} label="BackGround" type="bannerPrefBtnAcptAll" description="" />
                            <ButtonTheme customStyles={handleCustomButtonStyles} background={customThemeStyle?.theme?.bannerPrefBtnAcptAllTextColor || "#000"} label="TextColor" type="bannerPrefBtnAcptAll" description="" />
                            <ButtonTheme customStyles={handleCustomButtonStyles} background={customThemeStyle?.theme?.bannerPrefBtnAcptAllBorder || "#fff"} label="Border" type="bannerPrefBtnAcptAll" description="" />
                          </>
                        )} 
                  </div>
                </div>
            </Tabs.Panel>

            <Tabs.Panel value="content">
              <>
                <TextInput mb={10} mt={20}
                  label="Modal Title"
                  placeholder="Modal Title"
                  defaultValue={bannerContent.modalTitle}
                  onChange={(e) => handleContentChange("modalTitle", e.target.value, false, "")}
                />
                <Text size="sm" mt={10} mb="xs" fw={600}>Modal Description{" "}</Text>
                <ReactQuill theme="snow" value={bannerContent.modalDescription} onChange={(value) => handleContentChange("modalDescription", value, false, "")} />
                {regulation.value === 'us' && (
                  <>
                    <Text size="sm" mt={10} mb="xs" fw={600}>Description{" "}</Text>
                    <ReactQuill theme="snow" value={bannerContent.modalSubDescription} onChange={(value) => handleContentChange("modalSubDescription", value, true, "")} />
                    <TextInput mb={10} mt={20}
                        label="Do Not Sell My Personal Information"
                        placeholder="Do Not Sell My Personal Information"
                        defaultValue={bannerContent.NotSellData}
                        onChange={(e) => handleContentChange("NotSellData", e.target.value, false, "")}
                    />
                  </>
                )}     
                {regulation.value === 'gdpr' && (
                  <>
                <TextInput mb={10}
                  label="Necessary Cookies"
                  placeholder="Necessary Cookies"
                  defaultValue={bannerContent.options.Necessary}
                  onChange={(e) => handleContentChange("Necessary", e.target.value, true, "options")}
                />
                <TextInput mb={10}
                  label="Preferences Cookies"
                  placeholder="Preferences Cookies"
                  defaultValue={bannerContent.options.Preferences}
                  onChange={(e) => handleContentChange("Preferences", e.target.value, true, "options")}
                />
                <TextInput mb={10}
                  label="Statistics Cookies"
                  placeholder="Statistics Cookies"
                  defaultValue={bannerContent.options.Statistics}
                  onChange={(e) => handleContentChange("Statistics", e.target.value, true, "options")}
                />
                <TextInput mb={10}
                  label="Marketing Cookies"
                  placeholder="Marketing Cookies"
                  defaultValue={bannerContent.options.Marketing}
                  onChange={(e) => handleContentChange("Marketing", e.target.value, true, "options")}
                />
                <TextInput mb={10}
                  label="Unclassified Cookies"
                  placeholder="Unclassified Cookies"
                  defaultValue={bannerContent.options.Unclassified}
                  onChange={(e) => handleContentChange("Unclassified", e.target.value, true, "options")}
                />
                <Text size="sm" mt={10} mb="xs" fw={600}>Necessary Description{" "}</Text>
                <ReactQuill theme="snow" value={bannerContent.descriptions.Necessary} onChange={(value) => handleContentChange("Necessary", value, true, "descriptions")} />
                <Text size="sm" mt={10} mb="xs" fw={600}>Preferences Description{" "}</Text>
                <ReactQuill theme="snow" value={bannerContent.descriptions.Preferences} onChange={(value) => handleContentChange("Preferences", value, true, "descriptions")} />
                <Text size="sm" mt={10} mb="xs" fw={600}>Statistics Description{" "}</Text>
                <ReactQuill theme="snow" value={bannerContent.descriptions.Statistics} onChange={(value) => handleContentChange("Statistics", value, true, "descriptions")} />
                <Text size="sm" mt={10} mb="xs" fw={600}>Marketing Description{" "}</Text>
                <ReactQuill theme="snow" value={bannerContent.descriptions.Marketing} onChange={(value) => handleContentChange("Marketing", value, true, "descriptions")} />
                <Text size="sm" mt={10} mb="xs" fw={600}>Unclassified Description{" "}</Text>
                <ReactQuill theme="snow" value={bannerContent.descriptions.Unclassified} onChange={(value) => handleContentChange("Unclassified", value, true, "descriptions")} />
                <TextInput mb={10} mt={10}
                  label={`"Reject All" Button`}
                  placeholder={`"Reject All" Button`}
                  defaultValue={bannerContent.Reject}
                  onChange={(e) => handleContentChange("Reject", e.target.value, false, "")}
                />
                <TextInput mb={10}
                  label={`"Allow Selection" Button`}
                  placeholder={`"Allow Selection" Button`}
                  defaultValue={bannerContent.AllowSelection}
                  onChange={(e) => handleContentChange("AllowSelection", e.target.value, false, "")}
                />
                <TextInput mb={10} mt={10}
                  label={`"Accept All" Button`}
                  placeholder={`"Accept All" Button`}
                  defaultValue={bannerContent.Accept}
                  onChange={(e) => handleContentChange("Accept", e.target.value, false, "")}
                />
                </>
                )}
                {regulation.value === 'us' && (
                    <>
                      <TextInput mb={10}
                        label={`"Save Preferences" Button`}
                        placeholder={`"Save Preferences" Button`}
                        defaultValue={bannerContent.savePreferences}
                        onChange={(e) => handleContentChange("savePreferences", e.target.value, false, "")}
                      />
                      <TextInput mb={10} mt={10}
                        label={`"Cancel" Button`}
                        placeholder={`"Cancel" Button`}
                        defaultValue={bannerContent.Cancel}
                        onChange={(e) => handleContentChange("Cancel", e.target.value, false, "")}
                      />
                    </>
                )}
              </>
            </Tabs.Panel>
          </Tabs>  

        </Drawer>
        <Drawer
          className="banner-centent-drawer"
          opened={openedBannerContent}
          onClose={closeBannerContent}
          title="Re Content"
          overlayProps={{ backgroundOpacity: 0, blur: 0 }}
          size="xs"
          closeOnClickOutside={false}
          style={{
            backgroundOpacity: 0,
            blur: 0,
            style: { pointerEvents: "none" },
          }}
          withinPortal={true}
        >
          <Tabs variant="pills" defaultValue="design">
            <Tabs.List>
              <Tabs.Tab value="design">
                Design
              </Tabs.Tab>
              {data && data.category == 'ULTIMATE' && (
                <Tabs.Tab value="content">
                  Content
                </Tabs.Tab>
              )}
            </Tabs.List>

            <Divider mt={20} />      

            <Tabs.Panel value="design">
                  <div>
                    <div>
                        <BannerTheme customStyles={handleCustomStyles} background={customThemeStyle?.theme?.bannerReconsentBg || "#fff"} name="bannerReconsentBg" label="Background" description="" />
                        <BannerTheme customStyles={handleCustomStyles} background={customThemeStyle?.theme?.bannerReconsentTextColor || "#000"} name="bannerReconsentTextColor" label="Text Color" description="Choose the color of all texts within the banner" />
                        <Divider my="sm" />
                        <Group justify="left"><Text size="xs"> Button 1 (Withdraw Consent) </Text></Group>
                        <ButtonTheme customStyles={handleCustomButtonStyles} background={customThemeStyle?.theme?.bannerReconsntBtnWithDrawBackGround || "#fff"} label="BackGround" type="bannerReconsntBtnWithDraw" description="" />
                        <ButtonTheme customStyles={handleCustomButtonStyles} background={customThemeStyle?.theme?.bannerReconsntBtnWithDrawTextColor || "#000"} label="TextColor" type="bannerReconsntBtnWithDraw" description="" />
                        <ButtonTheme customStyles={handleCustomButtonStyles} background={customThemeStyle?.theme?.bannerReconsntBtnWithDrawBorder || "#fff"} label="Border" type="bannerReconsntBtnWithDraw" description="" />
                        <Divider my="sm" />
                        <Group justify="left"><Text size="xs">Button 2 (Update Consent) </Text></Group>
                        <ButtonTheme customStyles={handleCustomButtonStyles} background={customThemeStyle?.theme?.bannerReconsntBtnUpdtCnsntBackGround || "#fff"} label="BackGround" type="bannerReconsntBtnUpdtCnsnt" description="" />
                        <ButtonTheme customStyles={handleCustomButtonStyles} background={customThemeStyle?.theme?.bannerReconsntBtnUpdtCnsntTextColor || "#000"} label="TextColor" type="bannerReconsntBtnUpdtCnsnt" description="" />
                        <ButtonTheme customStyles={handleCustomButtonStyles} background={customThemeStyle?.theme?.bannerReconsntBtnUpdtCnsntBorder || "#fff"} label="Border" type="bannerReconsntBtnUpdtCnsnt" description="" />
                    </div>
                </div>
            </Tabs.Panel>

            <Tabs.Panel value="content">
              <>
                <TextInput mb={10} mt={20}
                  label="Title"
                  placeholder="Title"
                  defaultValue={bannerContent.CookieConsent}
                  onChange={(e) => handleContentChange("CookieConsent", e.target.value, false, "")}
                />
                <TextInput mb={10} mt={20}
                  label="ConsentID"
                  placeholder="ConsentID"
                  defaultValue={bannerContent.ConsentID}
                  onChange={(e) => handleContentChange("ConsentID", e.target.value, false, "")}
                />
                <TextInput mb={10} mt={20}
                  label="Timestamp"
                  placeholder="Timestamp"
                  defaultValue={bannerContent.Timestamp}
                  onChange={(e) => handleContentChange("Timestamp", e.target.value, false, "")}
                />
                <Text size="sm" mt={10} mb="xs" fw={600}>ConsentGiven{" "}</Text>
                <ReactQuill theme="snow" value={bannerContent.ConsentGiven} onChange={(value) => handleContentChange("ConsentGiven", value, false, "")} />
                <TextInput mb={10}
                  label="Necessary Cookies"
                  placeholder="Necessary Cookies"
                  defaultValue={bannerContent.options.Necessary}
                  onChange={(e) => handleContentChange("Necessary", e.target.value, true, "options")}
                />
                <TextInput mb={10}
                  label="Preferences Cookies"
                  placeholder="Preferences Cookies"
                  defaultValue={bannerContent.options.Preferences}
                  onChange={(e) => handleContentChange("Preferences", e.target.value, true, "options")}
                />
                <TextInput mb={10}
                  label="Statistics Cookies"
                  placeholder="Statistics Cookies"
                  defaultValue={bannerContent.options.Statistics}
                  onChange={(e) => handleContentChange("Statistics", e.target.value, true, "options")}
                />
                <TextInput mb={10}
                  label="Marketing Cookies"
                  placeholder="Marketing Cookies"
                  defaultValue={bannerContent.options.Marketing}
                  onChange={(e) => handleContentChange("Marketing", e.target.value, true, "options")}
                />
                <TextInput mb={10}
                  label="Unclassified Cookies"
                  placeholder="Unclassified Cookies"
                  defaultValue={bannerContent.options.Unclassified}
                  onChange={(e) => handleContentChange("Unclassified", e.target.value, true, "options")}
                />
                <TextInput mb={10}
                  label={`"Withdraw Consent" Button`}
                  placeholder={`"Withdraw Consent" Button`}
                  defaultValue={bannerContent.WithdrawConsent}
                  onChange={(e) => handleContentChange("WithdrawConsent", e.target.value, false, "")}
                />
                <TextInput mb={10}
                  label={`"Update Consent" Button`}
                  placeholder={`"Update Consent" Button`}
                  defaultValue={bannerContent.UpdateConsent}
                  onChange={(e) => handleContentChange("UpdateConsent", e.target.value, false, "")}
                />
              </>
            </Tabs.Panel>
          </Tabs>  
        </Drawer>
      </>
    </React.Fragment>
  );
}

export { BannerConfiguration };
