import React, { useEffect, useState } from 'react';
import { Select, Group, Box } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { IconCaretDownFilled } from '@tabler/icons-react';
import { Wizard } from './consentV2/Wizard';

const DomainDropdown = (props: any) => {
  const [loading, setLoading] = useState(false);
  const [domainData, setDomainData] = useState([]);
  const [currentSelection, setCurrentSelection] = useState(props.defaultSelection);
  const [showWizard, setShowWizard] = useState(false); // State to manage Wizard visibility
  const urlParams = new URLSearchParams(window.location.search);
  const websiteParam = urlParams.get('website');

  const getDomainData = async () => {
    setLoading(true);
    try {
      const response = await props.consentService.getDomains();
      console.log("API Response:", response); // Log the response

      if (response && response.data) {
        const formattedData = response.data.map((domain: any) => ({
          value: domain.domainId || domain.domain,
          label: domain.domain || domain.domainId,
        }));

        formattedData.push({ value: "add_new_site", label: "➕ New Site" });

        setDomainData(formattedData);

        if (formattedData.length === 2) {
          const { value, label } = formattedData[0];
          setCurrentSelection(value);
          props.handleCurrentDomain(value, label);
        } else if (!props.defaultSelection && formattedData.length > 1) {
          
          if (websiteParam) {
            const fallback = formattedData.find((d:any) => d.value === websiteParam);
            if (fallback) {
              setCurrentSelection(fallback.value);
              props.handleCurrentDomain(fallback.value, fallback.label);
              return;
            }
          }

          const { value, label } = formattedData[0];
          setCurrentSelection(value);
          props.handleCurrentDomain(value, label);
        }
      }
    } catch (error) {
      console.error("Error fetching domains:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getDomainData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.refresh]);

  return (
    <Group gap={15} style={{ display: domainData.length > 0 ? 'block' : 'none' }}>
      {domainData.length > 0 && (
        <>
        <Select
          allowDeselect={false}
          value={currentSelection} // Bind the state value
          placeholder="Please select your Domain"
          data={domainData}
          onChange={(value: string | null, option: any) => {
            if (value === "add_new_site") {
              setShowWizard(true); // Show the Wizard component
              return; // Prevent dropdown from closing
            }
            setShowWizard(false); // Hide Wizard if another domain is selected
            if (value) {
              setCurrentSelection(value);
              props.handleCurrentDomain(value, option.label);
            }
          }}
          disabled={loading}
          styles={{
            option: {
              textOverflow: 'ellipsis', // Enable ellipsis for dropdown items
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            },
          }}
          rightSection={<IconCaretDownFilled size={15} />}
          rightSectionWidth={30} // Ensure adequate space for the icon
        />
        {showWizard && (
            <Box mt="md">
              <Wizard />
            </Box>
          )}
        </>
      )}
    </Group>
  );
};

export default DomainDropdown;
