import {
  Grid,
  Text,
  Container,
  Group,
  Card,
  Button,
  Title,
  Image,
  Stack,
  em,
  Center,
  LoadingOverlay,
  Space,
  Alert
} from "@mantine/core";
import {
  IconAlertCircle,
  IconChartPie,
  IconClockRecord,
  IconDoorEnter,
  IconDoorExit,
} from "@tabler/icons-react";
import { Link } from 'react-router-dom';
import React, { useState, useEffect, useContext, useRef } from "react";
import { ConfigContext } from "../../Context/configContext";
import { Wizard } from "./consentV2/Wizard";
import dashBoardBg from "../../images/dashboard-bg.png";
import classes from './dashboad.module.css';
import { IconArrowNarrowRight } from "@tabler/icons-react";
import { useMediaQuery } from '@mantine/hooks';
import { ScanService } from "../../services/scanService";
import { ConsentService } from "../../services/consentService";
import { showNotification } from "@mantine/notifications";
import apiClient from "../../services/apiClient";
import { getAuthorizationHeader } from "../../utils/getAuthorizationHeader";
import { AuthService } from "@/services/AuthService";

interface AnalyticsResponse {
  totalOptIns: number;
  totalOptOuts: number;
  averageTimeToConsent: number;
  mostAcceptedCookie: string;
  leastAcceptedCookie: string;
  chartData: Array<any>; // You can define a more specific type if needed
}

function DashboardPage(props:any): JSX.Element {
  const { config } = useContext(ConfigContext);
  const [domainId, setDomainId] = useState<any | null>(props?.currentConsentData?.domainId || localStorage.getItem('activeDomainId'));
  const [isShowWizard, setIsShowWizard] = useState(false);
  const isMobile = useMediaQuery(`(max-width: ${em(750)})`);
  const [necessaryCount, setNecessaryCount] = useState(0);
  const [preferencesCount, setPreferencesCount] = useState(0);
  const [statisticsCount, setStatisticsCount] = useState(0);
  const [marketingCount, setMarketingCount] = useState(0);
  const [unClassifiedCount, setUnClassifiedCount] = useState(0);
  const [analyticsData, setAnalyticsData] = useState<any>(null);
  const [isDataAvailable, setIsDataAvailable] = useState(false); // Initialize to false
  const [loading, setLoading] = useState(false);
  const [isUserHaveDomain, setIsUserHaveDomain] = useState(false);  
  const isMounted = useRef(false);
  const [time, setTime] = useState("01:");
  const [seconds, setSeconds] = useState(60);
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [isResend, setIsResend] = useState(true);
  const [isLoad, setIsLoad] = useState(false);
  const [domains, setDomains] = useState<any[]>([]);

  const [showEmailAlert, setShowEmailAlert] = useState(
    localStorage.getItem("emailVerified") === "false"
  );
  
  const consentService = new ConsentService(config!);
  const authService = new AuthService(config!);

  useEffect(() => {
    getDomainData();
    if(domainId) {
      getDomainScanDataById(domainId);
      fetchAnalyticsData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domainId]);

  const formatAverageTimeToConsent = (timeInSeconds:number) => {
    const roundedTime = Math.round(timeInSeconds);
    
    if (roundedTime < 60) {
      return `${roundedTime} sec`;
    } else {
      const minutes = Math.floor(roundedTime / 60);
      const seconds = roundedTime % 60;
      return `${minutes} min ${seconds} sec`;
    }
  };

  const getDomainData = async () => {
    setLoading(true);
    try {
      const response = await consentService.getDomains();
      console.log("API Response:", response); // Log the response

      if (response && response.data) {
        setDomains(response?.data || []);
        setIsUserHaveDomain(response?.data?.length > 0);
        setIsShowWizard(response?.data?.length <=1);
        setDomainId(props?.currentConsentData?.domainId || response?.data[0]?.domainId);
      }
    } catch (error) {
      console.error("Error fetching domains:", error);
    } finally {
      setLoading(false);
      setIsLoad(true);
    }
  };

  const data = [
    {
      title: "Total Opt-ins",
      subTitle: '',
      description: analyticsData?.totalOptIns?.toString(),
      icon: <IconDoorEnter className="primary" size={70} stroke={1}/>,
      color: "var(--mantine-primary-color-filled)",
      bgColor: "#FFE5B4",
      span:4
    },
    {
      title: "Total Opt-outs",
      subTitle: '',
      description: analyticsData?.totalOptOuts?.toString(),
      icon: <IconDoorExit size={70} color="blue" stroke={1} />,
      color: "#4469bc",
      bgColor: "#f2f4fa",
      span:4
    },
    {
      title: "Avg Time to Consent",
      subTitle: '',
      description: analyticsData?.averageTimeToConsent ? formatAverageTimeToConsent(analyticsData?.averageTimeToConsent): "N/A",      
      icon: <IconClockRecord size={70} color="orange" stroke={1} />,
      color: "#4469bc",
      bgColor: "#f2f4fa",
      span:4
    },
    {
      title: "Most Consented Cookie",
      subTitle: 'cookie that users most likely give consent to: ',
      description: analyticsData?.mostAcceptedCookie?.toString(),
      icon: <IconChartPie size={70} color="blue" stroke={1} />,
      color: "#4469bc",
      bgColor: "#f2f4fa",
      span:6
    },
    {
      title: "Least Consented Cookie",
      subTitle: 'cookie that users least likely give consent to: ',
      description: analyticsData?.leastAcceptedCookie?.toString(),
      icon: <IconChartPie size={70} color="orange" stroke={1} />,
      color: "#4469bc",
      bgColor: "#f2f4fa",
      span:6
    },
  ];

  const fetchAnalyticsData = async () => {
    if (domainId) {
      setLoading(true);
      try {
        const response = await apiClient.get<AnalyticsResponse>(
          `${config!.apiBaseUrl}/domains/${domainId}/analytics`,
          {
            params: {
              startDate: '',
              endDate: '',
            },
            headers: getAuthorizationHeader()
          }
        );
        setAnalyticsData(response.data);
        setIsDataAvailable(response.data.chartData.length > 0);
      } catch (error) {
        console.error("Error fetching analytics data:", error);
        setIsDataAvailable(false);
      } finally {
        setLoading(false);
      }
    }
  };

  const getDomainScanDataById = async(domainId:string) => {
    if(domainId) {
      const scanService = new ScanService(config!);
      try {
        setLoading(true);
        const res = await scanService.getScanReport(domainId);
        if(res){
          setNecessaryCount(res?.latestScanResult?.necessaryCookies || 0);
          setPreferencesCount(res?.latestScanResult?.preferenceCookies || 0);
          setStatisticsCount(res?.latestScanResult?.statisticsCookies || 0);
          setMarketingCount(res?.latestScanResult?.marketingCookies || 0);
          setUnClassifiedCount(res?.latestScanResult?.unclassifiedCookies || 0);
        } 
      } catch (error) {
        console.error("Error fetching domain scan data:", error);
        showNotification({ color: "red", message: "Failed to fetch domain scan data." });
      } finally {
        setLoading(false);
      }
    }
  };

  const handleGetStartedClick = () => {
    setIsShowWizard(true);
  };

  const handleRefresh = (domainID:any) => {
    setDomainId(domainID);
    localStorage.setItem("newUser", "false");
    props.onDomainAdded();
  };

  useEffect(() => {
    const isVerified = localStorage.getItem("emailVerified");
  
    if (isVerified === "false") {
      const token = localStorage.getItem("token");
      
      if (!token) return;
  
      const eventSource = new EventSource(`${config!.apiBaseUrl}/auth/verification/event?token=${token}`);
  
      eventSource.addEventListener("success", (event) => {
        const data = JSON.parse(event.data);
        console.log("✅ Email verified SSE:", data);
      
        localStorage.setItem("emailVerified", "true");
        setShowEmailAlert(false);
        showNotification({
          title: "Email Verified",
          message: "Your email has been successfully verified!",
          color: "green"
        });
        eventSource.close();
      });
  
      eventSource.addEventListener("error", (event:any) => {
        console.error("❌ SSE Error Event:", event.data);
        showNotification({
          title: "Verification Failed",
          message: "There was an error processing the verification.",
          color: "red"
        });
        eventSource.close();
      });

      return () => {
        eventSource.close();
      };
    }
  }, [config]);

  const resendVerificationEmail = async () => {
      setLoading(true);
      setTime('01:');
      try {
          if(props?.user?.email) {
              await authService.resendEmail(props?.user?.email, 'verification').then((res:any) => {
                   setLoading(false)
                   if (res?.statusText == 'OK') {
                     setTimeout(function () {
                       setSeconds(60);
                       timer();
                       setIsResend(false);
                       setIsEmailSent(true);
                       showNotification({ color: "green", message: "Verification email resent successfully"});
                     }, 1000);
                   } else {
                       if(res?.response?.data == 'Failed to send verification link') {
                           setIsEmailSent(false);
                       }
                       showNotification({ color: "red", message: res?.response?.data});
                   }
              })
          }
      } catch (error) {
           showNotification({ color: "red", message: "Something went wrong !" });
      }
  };

  const timer = () => {
    let intervalId:any;
    let secondsLeft = 60;
    setTime('01:');
    intervalId = setInterval(() => {
      setTime('00:');
      if (secondsLeft > 0) {
        setSeconds(secondsLeft);
        setSeconds(secondsLeft - 1);
      } else {
        setIsResend(true);
        clearInterval(intervalId);
      }
      secondsLeft--;
    }, 1000);
  };
  
  return (
    <React.Fragment>
      {loading && (
          <LoadingOverlay visible={true} zIndex={1000}   overlayProps={{ radius: 'sm', blur: 2 }} loaderProps={{ color: 'green', type: 'bars' }}/>
      )}
    <>
    {showEmailAlert && (
      <Container size="lg">
        <Alert
          icon={<IconAlertCircle size="1rem" />}
          color="orange"
          withCloseButton
          onClose={() => setShowEmailAlert(false)}
          radius="md"
          variant="light"
        >
          <div style={{ display: 'flex' }}>
            Check the <strong>{localStorage.getItem("userEmail")}</strong> mailbox to verify your email address.{' '}
            {isResend && (
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  resendVerificationEmail();
                }}
                style={{ color: '#1a73e8', textDecoration: 'none', fontWeight: 500 }}
              >
                &nbsp; Resend Verification Email
              </a>
            )}

            {!isResend && isEmailSent && (
              <Group gap={0} wrap="nowrap" ml={10}>
                <Text size="sm">Didn't receive email? Resend in &nbsp;</Text>
                <Text size="sm" fw={500}>{time}</Text>
                <Text size="sm" fw={500}>
                  {seconds < 10 ? '0' + seconds : seconds === 60 ? '00' : seconds}
                </Text>
              </Group>
            )}
          </div>
        </Alert>
      </Container>
    )}
    {isLoad && (
    <>
    {isUserHaveDomain ?
      <Container size="lg" py="lg">
        <Grid gutter="md" style={{ alignItems: "stretch" }}>
          {/* News Updates */}
          <Grid.Col span={{ base: 12, md: 6, lg: 7 }}>
          <Card
              shadow="sm"
              padding="lg"
              style={{
                display: "flex",
                flexDirection: "column",
                minHeight: "300px",
                height: "100%",
              }}
            >
              <Title order={4}>Consent Analytics</Title>
              <Space my="xs" />
              <Grid>
                {isDataAvailable ? (
                  data.map((stat, index) => (
                    <Grid.Col key={index} span={stat.span}>
                      <Card shadow="xs" padding="sm" bg="#f2f4fa">
                        <Text size="sm" fw={700}>
                          {stat.title}
                        </Text>
                        <Text size="sm">{stat.description}</Text>
                      </Card>
                    </Grid.Col>
                  ))
                ) : (
                  <Grid.Col span={12}>
                    <Center h={135}>
                      <Text>No Data Available</Text>
                    </Center>
                  </Grid.Col>
                )}
              </Grid>
              <Group justify="right" mt="auto">
                <Button variant="subtle" component={Link} to={`/analytics`}>
                  View details →
                </Button>
              </Group>
            </Card>
          </Grid.Col>
      
          {/* Recent Scan Report */}
          <Grid.Col span={{ base: 12, md: 6, lg: 5 }}>
            <Card
              shadow="sm"
              padding="lg"
              style={{
                display: "flex",
                flexDirection: "column",
                minHeight: "300px",
                height: "100%",
              }}
            >
              <Title order={4}>Recent Scan Report</Title>
              <Space my="xs" />
              <Stack gap={8}>
                <Text>Necessary: {necessaryCount}</Text>
                <Text>Preference: {preferencesCount}</Text>
                <Text>Statistics: {statisticsCount}</Text>
                <Text>Marketing: {marketingCount}</Text>
                <Text>Unclassified: {unClassifiedCount}</Text>
              </Stack>
              <Group justify="right" mt="auto">
                <Button variant="subtle" component={Link} to={`/privacy-audits`}>
                  View detailed report →
                </Button>
              </Group>
            </Card>
          </Grid.Col>
        </Grid>
      
        <Grid gutter="md" style={{ alignItems: "stretch" }}>
          {/* Consent Analytics */}
          <Grid.Col span={{ base: 12, md: 6, lg: 7 }}>
            <Card
              shadow="sm"
              padding="lg"
              style={{
                display: "flex",
                flexDirection: "column",
                minHeight: "300px",
                height: "100%",
              }}
            >
              <Title order={4}>News Updates</Title>
              <Space my="xs" />
              <Stack gap={5}>
                <Text fw={500}>GDPR Compliance Changes</Text>
                <Text size="sm">
                  New updates on the GDPR compliance affecting data handling practices.
                </Text>
                <Text fw={500} mt={10}>CCPA Amendments</Text>
                <Text size="sm">
                  Recent amendments in CCPA that businesses need to be aware of.
                </Text>
                <Text fw={500} mt={10}>Global Data Privacy Laws</Text>
                <Text size="sm">
                  An overview of data privacy laws being enacted globally.
                </Text>
              </Stack>
            </Card>
          </Grid.Col>
      
          {/* Consent Banner Configuration */}
          <Grid.Col span={{ base: 12, md: 6, lg: 5 }}>
            <Card
              shadow="sm"
              padding="lg"
              style={{
                display: "flex",
                flexDirection: "column",
                minHeight: "300px",
                height: "100%",
              }}
            >
              <Title order={4}>Consent Banner Configuration</Title>
              <Space my="xs" />
              <Text size="sm">
                Configure your consent banner to ensure legal compliance, build user
                trust, and provide clear, granular data preferences.
              </Text>
              <Text mt="sm" size="sm">
                By configuring your consent banner effectively, you safeguard user
                rights and ensure a compliant, user-centric platform experience.
              </Text>
              <Group justify="right" mt="auto">
                <Button variant="subtle" component={Link} to={`/consentBanners`}>
                  Start/Update Configuration →
                </Button>
              </Group>
            </Card>
          </Grid.Col>
        </Grid>
        {localStorage.getItem("newUser") && localStorage.getItem("newUser") == 'true'  && isShowWizard && domains.length === 1 && (
          <Wizard domainId={domainId} activeStep={domainId ? 2 : 1} domainName={props.currentConsentData.domain || domains[0].domainName} handleRefresh={handleRefresh} />
        )} 
      </Container>   
      :
          <>
          <Container style={{maxWidth: '100%', overflow:'hidden'}} pl={0} pr={0}>
            <div className={classes.wrapper}>
                <Grid align="center" gutter="lg" mt={isMobile?"xl":"0"}>
                  <Grid.Col span={{ base: 12, sm:12, md: 8, lg:8 }}>
                    <Stack pl={30}>
                      <Title order={1} style={{ fontSize: '2rem' }}>Welcome to Cookiex!</Title>
                      <Text size="md">
                        Your trusted partner in managing consent with ease and compliance.
                      </Text>
                      <Button mt={20} size="md" variant="filled" color="blue" style={{ width: 'fit-content' }} rightSection={<IconArrowNarrowRight />} onClick={handleGetStartedClick}>
                        Get Started
                      </Button>
                    </Stack>
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, sm:12, md: 4, lg:4 }}>
                    <Image src={dashBoardBg} alt="Illustration" className={classes.image}/>
                  </Grid.Col>
                </Grid>
              </div>
              <div style={{ backgroundColor: '#fff', padding:'2rem', minHeight: '100%'}}>
                <Title order={2} style={{ marginBottom: '1rem' }}>
                  Key Features
                </Title>
                <Grid gutter="md" mt={30}>
                  <Grid.Col span={{ base: 12, sm:12, md: 4, lg:4 }}>
                    <Card bg="#f5f5f5" padding="lg" radius="md" h={150}>
                      <Title order={4}>Automated Cookie Scanning</Title>
                      <Text size="sm" mt={10}>
                        Regularly scan your website to identify and categorize cookies and tracking technologies.
                      </Text>
                    </Card>
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, sm:12, md: 4, lg:4 }}>
                    <Card bg="#f5f5f5" padding="lg" radius="md" h={150}>
                      <Title order={4}>Customizable Consent Banners</Title>
                      <Text size="sm" mt={10}>
                        Tailor consent notices to align with your brand and inform users about data collection practices.
                      </Text>
                    </Card>
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, sm:12, md: 4, lg:4 }}>
                    <Card bg="#f5f5f5" padding="lg" radius="md" h={150}>
                      <Title order={4}>Consent Log Management</Title>
                      <Text size="sm" mt={10}>
                        Securely maintain detailed records of user consent decisions to ensure transparency.
                      </Text>
                    </Card>
                  </Grid.Col>
                </Grid>
              </div>
            </Container>
            {isShowWizard && domains.length === 0 && (
              <Wizard handleRefresh={handleRefresh} />
            )}
          </>
        }  
      </>
      )}
      </>
    </React.Fragment>
  );
}

export { DashboardPage };
