import { createContext, useEffect, useState } from "react";
import { UserProfile } from "../Models/User";
import { useNavigate, useLocation } from "react-router-dom";
import { showNotification } from '@mantine/notifications';
import React from "react";
import axios from "axios";
import { jwtDecode } from 'jwt-decode';

type UserContextType = {
  user: UserProfile | null;
  token: string | null;
  loginUser: (res: any) => void;
  logout: () => void;
  isLoggedIn: () => boolean;
};

interface JwtPayload {
  exp: number;
  name:string;
  sub:string;
}

type Props = { children: React.ReactNode };

const UserContext = createContext<UserContextType>({} as UserContextType);

export const UserProvider = ({ children }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [token, setToken] = useState<string | null>(null);
  const [user, setUser] = useState<UserProfile | null>(null);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    const user = localStorage.getItem("user");
    const token = localStorage.getItem("token");
    if (user && token) {
      setUser(JSON.parse(user));
      setToken(token);
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
    setIsReady(true);
  }, []);

  const loginUser = (res: any) => {
    if (res) {
      localStorage.setItem("token", res?.data.token);
      localStorage.setItem("newUser", res?.data?.newUser === true ? "true" : "false");
      localStorage.setItem("emailVerified", res?.data?.emailVerified === true ? "true" : "false");

      const response: JwtPayload = jwtDecode(res?.data.token);
      const userObj = {
        name: response?.name,
        email: response?.sub,
        newUser: res?.data?.newUser === true,
        emailVerified: res?.data?.emailVerified === true
      };      
      localStorage.setItem("user", JSON.stringify(userObj));
      setToken(res?.data.token!);
      setUser(userObj!);
      showNotification({ color: "green", message: "Login Success!" });

      const redirectTo = location.state?.from || "/dashboard";
      navigate(redirectTo, { replace: true });
    }
  };

  const isLoggedIn = () => {
    if (!token) return false;
    const decoded: JwtPayload = jwtDecode(token);
    const currentTime = Date.now() / 1000; // Convert to seconds
    return !!user && decoded.exp > currentTime;
  };

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("activeDomain");
    localStorage.removeItem("activeDomainId");
    localStorage.removeItem("emailVerified");    
    localStorage.removeItem("newUser");
    setUser(null);
    setToken("");
    navigate("/");
  };

  return (
    <UserContext.Provider
      value={{ loginUser, user, token, logout, isLoggedIn }}
    >
      {isReady ? children : null}
    </UserContext.Provider>
  );
};

export const useAuth = () => React.useContext(UserContext);
