
import {
  Text,
  Button,
  Card,
  LoadingOverlay,
  Modal,
  Group,
  Title
} from "@mantine/core";
import React, { useContext, useState } from "react";
import { showNotification } from '@mantine/notifications';
import { UserService } from "../../services/userService";
import { useAuth } from "../../Context/useAuth";
import { useNavigate } from 'react-router-dom';
import { useDisclosure } from '@mantine/hooks';
import { ConfigContext } from "../../Context/configContext";

function DeleteAccount(): JSX.Element {
  const { config } = useContext(ConfigContext);
  const [loading, setLoading] = useState(false);
  const router = useNavigate();
  const { logout } = useAuth();
  const [opened, { close, open }] = useDisclosure(false);

  const userService = new UserService(config!);

  const handleDeleteAccount = async () => {
      setLoading(true);
      const response = userService.deleteAccount();
      response.then((res) => {
         setLoading(false);
         showNotification({ color: "green", message: "Account deleted!" });
         logout();
         router("/register");
      });
  };

  const deleteAccountModal = () => {
        open();
  };

  const handleCloseDeleteAccountModal = () => {
      close();
  };
  return (
    <React.Fragment>
      {loading && (
        <LoadingOverlay visible={true} zIndex={1000}   overlayProps={{ radius: 'sm', blur: 2 }} loaderProps={{ color: 'green', type: 'bars' }}/>
      )}
      <>
      <Card shadow="sm" p="xl" mt="xl">
          <Text fw={700} mb={10}>
            Delete account
          </Text>
          <Text size="xs">Delete your account will permanently erase all associated data and cannot be undone.</Text>
          <Button onClick={() => {deleteAccountModal()}} mt={20} w={150} variant="outline" size="xs">
            Delete My Account
          </Button>
      </Card>
      <Modal opened={opened} onClose={close} size="550" title="">
        <Title order={3} mb="sm">Are You Sure You Want to Delete Your Account? </Title>
        <Text mb="md" size="sm">Deleting your account is a permanent action and cannot be undone. All of your saved preferences, consent data, and account information will be permanently erased.</Text>
        <Title order={5} mb="xl">Do you want to proceed? </Title>
        <Group justify="flex-end">
          <Button onClick={handleCloseDeleteAccountModal} variant="outline">Cancel</Button>
          <Button onClick={handleDeleteAccount} variant="filled">Delete Account</Button>
        </Group>
     </Modal>
      </>
    </React.Fragment>
  );
}

export { DeleteAccount };
